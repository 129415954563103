export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        library_categories :'', 
        library_category :'', 
        library_items :'', 
        library_item :'', 
    },
    
    getters : {
        library_categories: (state )=> state.library_categories,
        library_category: (state )=> state.library_category,
        library_items: (state )=> state.library_items,
        library_item: (state )=> state.library_item,
    },


    mutations : { 
        setlibrary_categories :(state,library_categories ) => (state.library_categories = library_categories), 
        setlibrary_category :(state,library_category ) => (state.library_category = library_category), 
         setlibrary_items :(state,library_items ) => (state.library_items = library_items), 
        setlibrary_item :(state,library_item ) => (state.library_item = library_item), 
    },

    actions : {
        // ==================================== Categories ==================================

        async get_library_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_library_categories')
            return new Promise((resolve, reject) => {
            axios.post("/moderator/library_categories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_categories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_library_category_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_library_category_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/moderator/library_categories/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setlibrary_category', response.data.res)
                        if (response.data.res.approval_status == 1) {
                            toast.success("Category Approved Successfully")
                        } else {
                            toast.success("Category Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },



        // ==================================== Resources ==================================

        async get_library_items( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_library_items')
            return new Promise((resolve, reject) => {
            axios.post("/moderator/library_items?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_items', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_library_item_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_library_item_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/moderator/library_items/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setlibrary_item', response.data.res)
                        if (response.data.res.approval_status == 1) {
                            toast.success("Resource Approved Successfully")
                        } else {
                            toast.success("Resource Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}