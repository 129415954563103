export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        levels :'', 
        level :'', 
    },
    
    getters : {
        levels: (state )=> state.levels,
        level: (state )=> state.level,
    },


    mutations : { 
        setlevels :(state,levels ) => (state.levels = levels), 
        setlevel :(state,level ) => (state.level = level), 
    },

    actions : {

        async get_levels( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_levels')
            return new Promise((resolve, reject) => {
            axios.post("/moderator/levels?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlevels', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_level_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_level_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/moderator/levels/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setlevel', response.data.res)
                        if (response.data.res.approval_status == 1) {
                            toast.success("Level Approved Successfully")
                        } else {
                            toast.success("Level Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}