export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        scholarships :'', 
        scholarship :'', 
    },
    
    getters : {
        scholarships: (state )=> state.scholarships,
        scholarship: (state )=> state.scholarship,
    },


    mutations : { 
        setscholarships :(state,scholarships ) => (state.scholarships = scholarships), 
        setscholarship :(state,scholarship ) => (state.scholarship = scholarship), 
    },

    actions : {

        async get_scholarships( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_scholarships')
            return new Promise((resolve, reject) => {
            axios.post("/moderator/scholarships?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setscholarships', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_scholarship_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_scholarship_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/moderator/scholarships/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setscholarship', response.data.res)
                        if (response.data.res.approval_status == 1) {
                            toast.success("Scholarship Approved Successfully")
                        } else {
                            toast.success("Scholarship Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}