import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import users  from './modules/users'
import curricula  from './modules/curricula'
import levels  from './modules/levels'
import classes  from './modules/classes'
import curriculum_classes  from './modules/curriculum_classes'
import subjects  from './modules/subjects'
import library  from './modules/library'
import teacher_resources  from './modules/teacher_resources'
import shop  from './modules/shop'
import adverts  from './modules/adverts'
import topics  from './modules/topics'
import sub_topics  from './modules/sub_topics'
import lessons from './modules/lessons'
import statistics from './modules/statistics'
import papers from './modules/papers'
import impacts  from './modules/impacts'
import contests  from './modules/contests'
import scholarships  from './modules/scholarships'
import special_teachers  from './modules/special_teachers'
import notifications  from './modules/notifications'
import delivery_area from './modules/delivery_area'

export default createStore({
  modules: {
    auth, 
    utils,
    users,
    curricula,
    levels,
    classes,
    curriculum_classes,
    subjects,
    library,
    teacher_resources,
    shop,
    adverts,
    topics,
    sub_topics,
    lessons,
    statistics,
    papers,
    scholarships,
    impacts,
    contests,
    special_teachers,
    notifications,
    delivery_area
  },
    
  plugins: [createPersistedState(
    {
      key : 'educan.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
