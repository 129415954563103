export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        delivery_areas :'', 
        delivery_area :'', 
    },
    
    getters : {
        delivery_areas: (state )=> state.delivery_areas,
        delivery_area: (state )=> state.delivery_area,
    },


    mutations : { 
        setdelivery_areas :(state,delivery_areas ) => (state.delivery_areas = delivery_areas), 
        setdelivery_area :(state,delivery_area ) => (state.delivery_area = delivery_area), 
    },

    actions : {
        async get_delivery_areas( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_delivery_areas')
            return new Promise((resolve, reject) => {
            axios.post("/moderator/delivery_areas?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setdelivery_areas', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_delivery_area_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_delivery_area_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/moderator/delivery_areas/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setdelivery_area', response.data.res)
                        if (response.data.res.approval_status == 1) {
                            toast.success("Delivery Area Approved Successfully")
                        } else {
                            toast.success("Delivery Area Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}