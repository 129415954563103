export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        curricula_classes :'', 
        curricula_class :'', 
    },
    
    getters : {
        curricula_classes: (state )=> state.curricula_classes,
        curricula_class: (state )=> state.curricula_class,
    },


    mutations : { 
        setcurricula_classes :(state,curricula_classes ) => (state.curricula_classes = curricula_classes), 
        setclasse :(state,curricula_class ) => (state.curricula_class = curricula_class), 
    },

    actions : {

        async get_curricula_classes( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_curricula_classes')
            return new Promise((resolve, reject) => {
            axios.post("/moderator/curricula_classes?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcurricula_classes', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_curricula_class_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_curricula_class_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/moderator/curricula_classes/approval_status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setclasse', response.data.res)
                        if (response.data.res.approval_status == 1) {
                            toast.success("Class Approved Successfully")
                        } else {
                            toast.success("Class Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },
    }
}